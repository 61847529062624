<template>
  <div class="row">
    <div class="col-12">
      <h2 v-if="!this.$route.params.id">Cadastro de Bairro</h2>
      <h2 v-if="this.$route.params.id">Atualização de Bairro</h2>

      <Card>
        <h4>Dados</h4>
        <form @submit.prevent="saveDistrict()">
          <div class="row">
            <div class="col">
              <base-input
                v-model="form.name"
                type="text"
                placeholder="Nome"
                label="Nome"
              >
                <HasErrorForm
                  :form="form"
                  field_name="name"
                  slot="helperText"
                />
              </base-input>

              <div class="form-row">
                <base-radio name="active" class="col-md-3" v-model="isActive">
                  Ativo
                </base-radio>

                <base-radio name="inactive" class="col-md-3" v-model="isActive">
                  Inativo
                </base-radio>
              </div>
            </div>

            <div class="col">
              <div class="form-row">
                <div class="col-md-6 dropdown-top">
                  <base-dropdown
                    title-classes="btn btn-warning btn-block"
                    titleTag="div"
                    :title="
                      selectedProvince
                        ? `Estado: ${selectedProvince.name}`
                        : 'Estado'
                    "
                  >
                    <a
                      v-for="province in provinces"
                      :key="province.id"
                      class="dropdown-item"
                      href="#"
                      @click="selectProvince(province)"
                      >{{ province.name }}</a
                    >
                  </base-dropdown>
                </div>

                <div class="col-md-6 dropdown-top">
                  <base-dropdown
                    title-classes="btn btn-warning btn-block"
                    titleTag="div"
                    :title="
                      selectedCity ? `Cidade: ${selectedCity.name}` : 'Cidade'
                    "
                  >
                    <a v-if="!selectedProvince" class="dropdown-item" href="#"
                      >Selecione um Estado</a
                    >
                    <a
                      v-if="selectedProvince && cities.length <= 0"
                      class="dropdown-item"
                      href="#"
                      >Nenhuma cidade encontrada</a
                    >
                    <span v-if="selectedProvince && cities.length > 0">
                      <a
                        v-for="city in cities"
                        :key="city.id"
                        class="dropdown-item"
                        href="#"
                        @click="selectCity(city)"
                        >{{ city.name }}</a
                      >
                    </span>
                  </base-dropdown>

                  <HasErrorForm
                    :form="form"
                    field_name="city_id"
                    slot="helperText"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="text-right">
            <base-button
              native-type="submit"
              type="warning"
              :disabled="loading"
            >
              Salvar
            </base-button>
          </div>
        </form>
      </Card>
    </div>
  </div>
</template>
<script>
import Form from 'vform'
import Card from '@/components/Cards/Card'
import { BaseDropdown } from '@/components'
import BaseInput from '@/components/Inputs/BaseInput'
import BaseRadio from '@/components/Inputs/BaseRadio'
import HasErrorForm from '@/components/HasErrorForm'

export default {
  name: 'NewEditDistrict',
  data: () => ({
    form: false,
    isActive: 'active',
    provinces: [],
    cities: [],
    selectedProvince: false,
    selectedCity: false,
    loading: false
  }),

  methods: {
    resetLocation () {
      this.selectedCity = false
      this.form.city_id = null
      this.form.district_id = null
    },
    async selectProvince (province) {
      this.selectedProvince = province
      const {
        data: { cities }
      } = await this.$http.get(`cities/${province.id}`)
      this.cities = cities
    },
    async selectCity (city) {
      this.selectedCity = city
      this.form.city_id = city.id
    },

    async saveDistrict () {
      this.loading = true

      try {
        if (this.district_id) {
          this.form.id = this.district_id
        }

        this.form.is_active = this.isActive === 'active' ? 1 : 0

        const {
          data: { data }
        } = await this.form.post('/districts')

        this.$notify({
          type: 'primary',
          message: this.district_id ? 'Bairro atualizado!' : 'Bairro Criado!',
          icon: 'tim-icons icon-bell-55'
        })

        this.district_id = data.id
        this.$router.push({ path: '/districts' })
      } catch (error) {
        //
      }
      this.loading = false
    }
  },
  async created () {
    const {
      data: { provinces }
    } = await this.$http.get('provinces')
    this.provinces = provinces

    if (this.$route.params.id !== undefined) {
      this.district_id = this.$route.params.id

      const {
        data: { data }
      } = await this.$http.get(`/districts/show/${this.$route.params.id}`)

      this.form = new Form({
        name: data.name
      })

      this.isActive = parseInt(data.is_active) ? 'active' : 'inactive'

      await this.selectProvince(
        this.provinces.find(p => p.id === parseInt(data.city.province_id))
      )
      await this.selectCity(
        this.cities.find(c => c.id === parseInt(data.city_id))
      )
    } else {
      this.form = new Form()

      await this.selectProvince(this.provinces.find(p => p.initials === 'PR')) // Paraná
      await this.selectCity(this.cities.find(c => c.ibge_code === '4108304')) // Foz do Iguaçu
    }
  },

  components: { Card, BaseInput, BaseDropdown, BaseRadio, HasErrorForm }
}
</script>

<style lang="css">
.dropdown-top {
  margin-top: 23px;
}
</style>
